import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import kantalogo from "../../assets/logo/logokanta.svg";
import menu from "../../assets/icons/menuHamburger.svg";
import close from "../../assets/icons/close.svg";

export default function Navbar() {
  const [showMobileNav, setShowMobileNav] = React.useState(false);

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  // Function to hide the mobile nav
  const closeMobileNav = () => {
    setShowMobileNav(false);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={kantalogo} alt="Logo" className={styles.logo} />
        </Link>
      </div>
      <nav className={styles.nav}>
        <Link to="/service">
          <p className={styles.linkText}>SERVICES</p>
        </Link>
        <Link to="/blog">
          <p className={styles.linkText}>BLOG</p>
        </Link>
        <Link to="/team">
          <p className={styles.linkText}>TEAM</p>
        </Link>
        <p
          className={styles.linkText}
          style={{
            color: "rgba(0,0,0,.5)",
            fontWeight: "400",
          }}
        >
          English
        </p>
        <div
          style={{
            width: "1px",
            height: "34px",
            backgroundColor: "#CCCCCC",
            margin: "0 16px",
          }}
        ></div>
        <div>
          <Link to="/waiting-list">
            <button className={styles.button}>Join Wait List</button>
          </Link>
        </div>
      </nav>
      <button className={styles.menu} onClick={toggleMobileNav}>
        <img src={menu} alt="Menu" />
      </button>
      <div
        className={styles.mobileNav}
        style={{
          display: showMobileNav ? "block" : "none",
        }}
      >
        <Link to="/service" onClick={closeMobileNav}>
          <p className={styles.linkText}>SERVICES</p>
          <div className={styles.line}></div>
        </Link>
        <Link to="/blog" onClick={closeMobileNav}>
          <p className={styles.linkText}>BLOG</p>
          <div className={styles.line}></div>
        </Link>
        <Link to="/team" onClick={closeMobileNav}>
          <p className={styles.linkText}>TEAM</p>
          <div className={styles.line}></div>
        </Link>
        <div>
          <p
            className={styles.linkText}
            style={{
              color: "rgba(0,0,0,.5)",
            }}
          >
            English
          </p>
          <div className={styles.line}></div>
        </div>

        <div className={styles.socialMedia}></div>
        <Link to="/waiting-list" onClick={closeMobileNav}>
          <button className={styles.buttonMobile} onClick={closeMobileNav}>
            Join Wait List
          </button>
        </Link>
        <button className={styles.close} onClick={toggleMobileNav}>
          <img src={close} alt="Close" />
        </button>
        <div className={styles.info}>
          <p>
            Join the Kanta Community to experience financial freedom, seamless
            trading, and exclusive benefits from using Kanta
          </p>
        </div>
      </div>
    </header>
  );
}
