import styles from "./styles.module.css";
import { useState } from 'react';

import kantalogo from "../../assets/logo/kantalogo.svg";

import fb from "../../assets/icons/Fb.svg";
import tw from "../../assets/icons/Tw.svg";
import ig from "../../assets/icons/Ig.svg";
import ln from "../../assets/icons/Ln.svg";

import mail from "../../assets/icons/mail.svg";

//illustrations
import polygon8 from "../../assets/illustrations/Polygon 5.svg";
import polygon9 from "../../assets/illustrations/Polygon 6.svg";

import { postSubscribers } from "../../utils/service";
import { ToastContainer, toast } from "react-toastify";
import { isValidEmail } from "../../utils/constant";

export default function Footer() {
   const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");

 
  
    const handleChange = (e) => {
      setEmail(e.target.value);
      if (isValidEmail(email)) {
        setError("");
        setEmail(e.target.value);
      } else {
        setError("Email is not valid.");
      }
    };

    const handleSubmit = async (e) => {
      console.log('clicked')
      e.preventDefault();
      setLoading(true);
      setError(null);
  
      const payload = { data: { email } };
  
        try {
          const result = await  postSubscribers(payload);
          console.log(result);
          toast.success("Yay 🎉, you are now a kantain!");
          setEmail("");
        } catch (err) {
          toast.error(err.message);
          setError(err.message);
        } finally {
          setLoading(false);
        }
    };

  return (
    <footer className={styles.footer}>
       <ToastContainer position="top-center" />
      <h1>Subscribe to our newsletter</h1>
      <p>
        Never miss a beat.
        <br />
        Get a weekly dose of exchange update, news,
        <br />
        secrets, tips, trends and in your inbox.
      </p>
      <form>
        <div className={styles.input}>
          <img src={mail} alt="Mail" />
          <input type="email" value={email} onChange={handleChange} placeholder="Email address" />
        </div>
        <button onClick={handleSubmit} disabled={error && true} >{loading? 'loading...' : 'Subscribe'}</button>
      </form>
      {error && (
        <p style={{ color: "red", marginTop: "1rem", fontFamily: "Poppins" }}>
          {" "}
          {error}
        </p>
      )}

      <div className={styles.content}>
        <div className={styles.info1}>
          <div className={styles.logo}>
            <img src={kantalogo} alt="Logo" />
          </div>
          <p>
            Convert and spend digital asset with
            <br />
            ease best-in-class automated
            <br />
            settlement platform powered by
            <br />
            blockchain technology.
          </p>
          <div className={styles.socials}>
            <img src={fb} alt="Facebook" />
            <a
              href="https://x.com/KantaExchange"
              target="_blank"
              rel="noreferrer"
            >
              <img src={tw} alt="Twitter" />
            </a>
            <a
              href="https://www.instagram.com/kantaexchange_?igsh=NDk5MDdpbGRmYmcz&utm_source=qr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={ig} alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/in/kanta-exchange-816634333?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              target="_blank"
              rel="noreferrer"
            >
              <img src={ln} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className={styles.info2}>
          <p>Exchange</p>
          <p>Payment</p>
          <p>Debit Card</p>
        </div>
        <div className={styles.info3}>
          <p>Financial Services</p>
          {/* <p>Fashion & Beauty</p> */}
          {/* <p>Agriculture</p> */}
        </div>
        <div className={styles.info4}>
          <p>Help center</p>
          <p>FAQ</p>
          <p>Contact</p>
          <p>Press</p>
        </div>
      </div>
      <div className={styles.polygon8}>
        <img src={polygon8} alt="polygon8" />
      </div>
      <div className={styles.polygon9}>
        <img src={polygon9} alt="polygon9" />
      </div>
      <p className={styles.copyRight}>
        © 2024 Kanta Exchange. All rights reserved.
      </p>
    </footer>
  );
}
