export function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Test the email against the regex pattern
    return emailPattern.test(email);
  }

  export const truncateText = (text, wordLimit) => {
    // Split the text into words
    const words = text.split(' ');

    // Truncate to the first 'wordLimit' words
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    } else {
      return text;
    }
  };
