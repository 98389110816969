import React from "react";
import styles from "./styles.module.css";

const CustomDropdown = ({ label, data, placeholder,onChange,value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60px",
          fontFamily: "Poppins"
        }}
      >
        <label htmlFor="Value">{label}</label>
      </div>
      <div className={styles.DropdownContainer}>
        {/* <label htmlFor="Value">{label}</label> */}
        <div className={styles.InputContainer}>
          <select
            name=""
            id=""
            style={{
              zIndex: "10",
              width: "100px",
              outline: "none",
            }}
          >
            {data?.map((value, index) => {
              return (
                <option key={index} value={value?.attributes.name}>
                  {value?.attributes.name}
                </option>
              );
            })}
          </select>
          <input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            type="number"
            style={{
              width: "100%",
              zIndex: "10",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomDropdown;
