import React from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import one from "../../assets/illustrations/1.svg";
import two from "../../assets/illustrations/2.svg";
import three from "../../assets/illustrations/3.svg";
import four from "../../assets/illustrations/4.svg";
import five from "../../assets/illustrations/5.svg";
import six from "../../assets/illustrations/6.svg";

const ServiceWrapper = () => {
  const data = [
    {
      id: 1,
      color: "rgba(217, 238, 206, 1)",
      text: "Exchange",
      details:
        "Stands out for its state-of-the-art design, geared towards facilitating smooth crypto-to-fiat settlements directly into your bank account, with a focus on maximizing the value of your cryptocurrency.",
      img: one,
    },
    {
      id: 2,
      color: "rgba(202, 236, 230, 1)",
      text: "Spend Crypto Like Fiat",
      details:
        "Kanta Scan to pay futures facilitates swift, straightforward, and secure payment transactions, allowing users to effortlessly utilize cryptocurrency while enabling businesses to receive payments in either fiat or Stablecoins.",
      img: two,
    },
    {
      id: 3,
      color: "rgba(206, 219, 255, 1)",
      text: "External  wallet connect ",
      details:
        "Enabling crypto users to leverage the remarkable features offered by Kanta through seamless connection with external wallets like. Meta mask, Ledger, Trust wallet, wallet connect, Eternl, Nest wallet, and many more that will be added as we expand",
      img: three,
    },
    {
      id: 4,
      color: "rgba(216, 195, 252, 1)",
      text: "Scan to pay",
      details:
        "Facilitates swift, & secure payment transactions, to effortlessly utilize cryptocurrency while enabling businesses to receive payments in either local fiat or stablecoins.",
      img: four,
    },
    {
      id: 5,
      color: "rgba(254, 255, 161, 0.8)",
      text: "Dapp Support ",
      details:
        "Offering access to financial products to unbanked and underbanked users in Nigeria and across Africa market, while bridging the gap between Web2 & Web3. Unlock financial freedom with KANTA.",
      img: five,
    },
    {
      id: 6,
      color: "rgba(206, 242, 152, 1)",
      text: "In App Wallet",
      details:
        "Enjoy the convenience of spending digital assets. Services like Bill Payment, Airtime Top-Up, Betting, Data Bundles, and an ever-expanding list of other services.",
      img: six,
    },
  ];
  return (
    <div className={styles.Container}>
      <Paragraph
        fontSize="36px"
        color="rgba(0, 0, 0, 0.5)"
        fontWeight={600}
          className={styles.ServiceTitle}
        text="Our Services"
      />
      <div className={styles.BoxContainer}>
        {data.map((info) => {
          return (
            <div
              key={info.id}
              style={{ background: info.color }}
              className={styles.Box}
            >
              <div >
                <img
                  src={info.img}
                  alt={info.text}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <Paragraph
                  fontFamily="Inter"
                  fontSize={"20px"}
                  fontWeight="600"
                  text={info.text}
                   className={styles.ServiceHeading}
                />
              </div>
              <Paragraph
                color="rgba(0, 0, 0, 0.9)"
                textAlign="center"
                fontSize="16px"
                fontWeight="400"
                className={styles.ServiceText}
                text={info.details}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceWrapper;
