import React from "react";
import styles from "./styles.module.css";
import CustomDropdown from "./CustomDropdown";
import { getCrypto, getExchange, getFiat, getNetwork } from "../../utils/service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const Conversion = ({ placeholder }) => {

  const [cryptoData, setCryptoData] = React.useState();
  const [fiatData, setFiatData] = React.useState();
  const [cryptoValue, setCryptoValue] = React.useState();
  const [fiatValue,setFiatValue] = React.useState();
  const [networkData, setNetworkData] = React.useState();
  const [exchangeData, setExchangeData] = React.useState();

  const fetchCryptoData = async () => {
      try {
        const result = await getCrypto(); 
        
        setCryptoData(result?.data);
      } catch (error) {
        toast.error(error.message)
      }
  };

  const fetchExchangeData = async () => {
      try {
        const result = await getExchange(); 
        
        setExchangeData(result?.data);
      } catch (error) {
       toast.error(error.message)
      }
  }
    

  const fetchFiatData = async () => {
      try {
        const result = await getFiat(); 
        
        setFiatData(result?.data);
      } catch (error) {
       toast.error(error.message)
      }
  };
  
   const fetchNetworkData = async () => {
      try {
        const result = await getNetwork(); 
        
        setNetworkData(result?.data);
      } catch (error) {
       toast.error(error.message)
      }
  };

  React.useEffect(() => {
    fetchCryptoData();
    fetchFiatData();
    fetchNetworkData();
    fetchExchangeData();
  },[])

  const handleCryptoValueChange = (e) => {
    setCryptoValue(e.target.value)
  }

  React.useEffect(() => {
    setFiatValue(cryptoValue * 1720);
  }, [cryptoValue])
  
  const filteredData = exchangeData?.filter(item => 
    (item?.attributes.fiat === fiatValue && item?.attributes.crypto === cryptoValue) || fiatValue === ''
    );
    console.log(filteredData,exchangeData);

  const handleFaitValue = () => {
    setFiatValue(cryptoValue * 1720);
     const filteredData = exchangeData?.filter(item => 
    (item?.attributes.fiat === fiatValue && item?.attributes.crypto === cryptoValue) || fiatValue === ''
    );
    console.log(filteredData)
  }


  return (
    <>
      <ToastContainer  position="top-center" />
      <CustomDropdown data={cryptoData} label="Value" value={cryptoValue} onChange={handleCryptoValueChange} placeholder={placeholder} />
      <CustomDropdown data={fiatData} label="Amount" value={fiatValue} onChange={handleFaitValue} placeholder={placeholder} />
      <div className={styles.RateContainer}>
        <p className={styles.Rate}>1 USDT = 1720 NGN</p>
      </div>
      <hr />
      <div className={styles.Network}>
        <p>Network</p>
        <select
          style={{
            zIndex: "10",
            color: "#000",
          }}
        >
          {
            networkData?.map((data) => {
              return (
                <option>{ data?.attributes?.network}</option>
              )
            })
          }          
        </select>
      </div>
    </>
  );
};

export default Conversion;
