import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Service from "./pages/Service/Service";
import Team from "./pages/Team/Team";
import WaitingList from "./pages/WaitingList/WaitingList";
import Blog from "./pages/Blog/Blog";

import withVisibility from "./hoc/withVisibility/withVisibility";

const FooterWithVisibility = withVisibility(Footer);

function App() {
  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/team" element={<Team />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/waiting-list" element={<WaitingList />} />
        </Routes>
        <FooterWithVisibility />
      </Router>
    </div>
  );
}

export default App;
