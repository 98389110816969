import { useEffect } from "react";

import ServiceHeaderWrapper from "../../wrappers/ServiceHeaderWrapper/ServiceHeaderWrapper";
import styles from "./styles.module.css";
import ServiceWrapper from "../../wrappers/ServiceSectionWrapper/ServiceWrapper";

import withVisibility from "../../hoc/withVisibility/withVisibility";

const ServiceHeaderWrapperWithVisibility = withVisibility(ServiceHeaderWrapper);
const ServiceWrapperWithVisibility = withVisibility(ServiceWrapper);

function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Service}>
      <ServiceHeaderWrapperWithVisibility />
      <ServiceWrapperWithVisibility />
    </div>
  );
}

export default Service;
