import React from "react";
import Paragraph from "../../ui/Paragragh/Paragraph";
import star from "../../assets/icons/starIcon.svg";
import styles from "./styles.module.css";
import Title from "../../ui/Title/Title";
import one from "../../assets/illustrations/s.svg";
import two from "../../assets/illustrations/t.svg";
import three from "../../assets/illustrations/b.svg";
import four from "../../assets/illustrations/e.svg";
import five from "../../assets/illustrations/n.svg";
import six from "../../assets/illustrations/euro.svg";
import Conversion from "../../components/Conversion/Conversion";
import sss from "../../assets/illustrations/SSS.svg";
import middle from "../../assets/illustrations/ss1topmiddle.svg";
import ss1 from "../../assets/illustrations/sss1.svg";
import ss2 from "../../assets/illustrations/SSS (1).svg";
import small from "../../assets/illustrations/smallsquare.svg";
import tri from "../../assets/illustrations/tri.svg";
import vector from "../../assets/illustrations/Vector.svg";
import vector1 from "../../assets/illustrations/Vector (1).svg";
import polygon from "../../assets/illustrations/Polygon 10.svg";
import frame from "../../assets/illustrations/headerwrapperframe.svg";

const HeaderWrapper = () => {
  const imageData = [one, two, three, four, five, six];
  
  return (
    <div className={styles.Container}>
      {/* illustrations */}

      <img src={sss} alt="" className={styles.sss} />
      <img src={middle} alt="" className={styles.middle} />
      <img src={ss1} alt="" className={styles.ss1} />
      <img src={ss2} alt="" className={styles.ss2} />
      <img src={small} alt="" className={styles.small} />
      <img src={small} alt="" className={styles.small2} />
      <img src={tri} alt="" className={styles.tri} />
      <img src={vector} alt="" className={styles.vector} />
      <img src={vector1} alt="" className={styles.vector1} />
      <img src={frame} alt="" className={styles.frame} />
      <div>
        <img src={polygon} alt="" className={styles.polygon} />
      </div>

      {/* end of illustrations */}
      <div style={{ width: "100%", marginTop: "2rem" }}>
        <div className={styles.ComingSoonContainer}>
          <img className={styles.Img} src={star} alt="" />
          <Paragraph text="Coming Soon" color="#5E9300" fontSize="1.75rem" />
          <img className={styles.Img} src={star} alt="" />
        </div>
      </div>

      <div className={styles.TitleContainer}>
        <span>
          <Title title="Embrace The Digital Currency" />
          <br />
          <Title title=" Era With " />
          &nbsp;
          <Title color="#5E9300" title=" KANTA Exchange" />
          <br />
        </span>
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Paragraph
            text="The most secure platform to effortlessly convert"
            fontWeight={400}
          />
          <br />
          <Paragraph
            text=" and spend cryptocurrencies like local fiat"
            fontWeight={400}
          />
        </div>
      </div>

      <div className={styles.IconContainer}>
        {imageData.map((data, index) => {
          return <img className={styles.Icon} key={index} src={data} alt="" />;
        })}
      </div>
      <div className={styles.ConversionContainer}>
        <Conversion placeholder="0" />
      </div>
      <p className={styles.Estimate}>Estimated settlement time 1 minutes</p>
    </div>
  );
};

export default HeaderWrapper;
