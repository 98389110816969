import React from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
// import phone from '../../assets/images/PhoneMockups.svg'
// import defi from "../../assets/images/defipic.svg";
import clip from "../../assets/illustrations/Clip path group.svg";
// import connection from "../../assets/illustrations/No Connection illustartion.svg";
import time from "../../assets/illustrations/Time Circle.svg";
import twoTokens from "../../assets/illustrations/Group 1000004250.svg";
import completed from "../../assets/illustrations/completed.svg";
import usdc from "../../assets/illustrations/usdc.svg";
import ngn from "../../assets/illustrations/ngn.svg";
import left from "../../assets/illustrations/arrow-left.svg";
import right from "../../assets/illustrations/arrow-right.svg";
import connection from "../../assets/icons/newIcon.png";
// import phone2 from "../../assets/images/exchangePhone.png";
import phoneMockup from "../../assets/images/mockupIphone.png"

const SecondWrapper = () => {
  const data = [
    {
      id: 1,
      text: "Spend crypto with ease ",
      img: clip,
    },
    {
      id: 2,
      text: "Swap crypto-to-fiat  ",
      img: twoTokens,
    },
    {
      id: 3,
      text: "Client side encryption  ",
      img: time,
    },
    {
      id: 4,
      text: "Multi-Chain support  ",
      img: connection,
    },
  ];
  return (
    <div className={styles.Container}>
      <div className={styles.FirstBox}>
        <div>
          <Paragraph
            className={styles.FirstBoxText}
            text="Secure, Private and"
          />
          <br />
          <Paragraph className={styles.FirstBoxText} text="Easy to Use" />
        </div>
        <div className={styles.SecureBox}>
          <Paragraph
            className={styles.SecureBoxText}
            text="Enjoy the optimal blend of DeFi & TradFi"
          />
          <div>
            <div className={styles.InnerContainer}>
              {data.map((info) => {
                return (
                  <div className={styles.CyptoContainer} key={info.id}>
                    <img src={info.img} alt="" className={styles.cryptoIcon} />
                    <Paragraph
                      whiteSpace="nowrap"
                      fontSize="16px"
                      fontWeight="400"
                      text={info.text}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.PhoneContainer}>
              <img src={phoneMockup} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.SecondBox}>
        <div>
          <img src={completed} alt="" />
        </div>
        <div className={styles.CryptoFiat}>
          <Paragraph className={styles.SecondBoxText} text="Crypto" />
          <img src={usdc} alt="" />
          <div>
            <img src={right} alt="" />
            <img src={left} alt="" />
          </div>
          <img src={ngn} alt="" />
          <Paragraph className={styles.SecondBoxText} text="Local Fiat" />
        </div>
        <div className={styles.TextContainer}>
          <Paragraph
            textAlign="center"
            fontWeight="400"
            fontSize="20px"
            text="Designed to simplify crypto conversion and spending directly from your decentralized wallet, while also contributing to the economic growth of traditional finance across the African market."
          />
        </div>
      </div>
    </div>
  );
};

export default SecondWrapper;
