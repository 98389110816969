import { useEffect } from "react";

import React from "react";
import BlogWrapper from "../../wrappers/BlogWrapper/BlogWrapper";
import BlogContentWrapper from "../../wrappers/BlogWrapper/BlogContentWrapper";

import withVisibility from "../../hoc/withVisibility/withVisibility";

const BlogWrapperWithVisibility = withVisibility(BlogWrapper);
const BlogContentWrapperWithVisibility = withVisibility(BlogContentWrapper);

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlogWrapperWithVisibility />
      <BlogContentWrapperWithVisibility />
    </div>
  );
};

export default Blog;
