 const API = process.env.REACT_APP_API_URL||'https://kanta-exchange.com';

export async function getEmailList() {
  const response = await fetch(`${API}/api/emails`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getFaq() {
  const response = await fetch(`${API}/api/faqs`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getExchange() {
  const response = await fetch(`${API}/api/exchanges`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getBlogs() {
  const response = await fetch(`${API}/api/blogs?populate=*`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getBlogCategory() {
  const response = await fetch(`${API}/api/categories`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getNetwork()  {
  const response = await fetch(`${API}/api/networks`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getCrypto()  {
  const response = await fetch(`${API}/api/cryptos`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const result = await response.json();
  return result;
}

export async function getFiat() {
    const response = await fetch(`${API}/api/fiats`);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const result = await response.json();
  return result;
}

export async function postEmail(data) {
  const response = await fetch(`${API}/api/emails`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify(data), 
  });

  if (!response.ok) {
    throw new Error('Failed to post credentials');
  }

  const result = await response.json();
  return result;
}

export async function postSubscribers(data) {
  const response = await fetch(`${API}/api/subscribes`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify(data), 
  });

  if (!response.ok) {
    throw new Error('Failed to post credentials');
  }

  const result = await response.json();
  return result;
}

