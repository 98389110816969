import React from "react";
import Paragraph from "../../ui/Paragragh/Paragraph";
import styles from "./styles.module.css";
import Title from "../../ui/Title/Title";
import one from "../../assets/illustrations/s.svg";
import two from "../../assets/illustrations/t.svg";
import three from "../../assets/illustrations/b.svg";
import four from "../../assets/illustrations/e.svg";
import five from "../../assets/illustrations/n.svg";
import six from "../../assets/illustrations/euro.svg";
import sss from "../../assets/illustrations/SSS.svg";
import middle from "../../assets/illustrations/ss1topmiddle.svg";
import ss1 from "../../assets/illustrations/sss1.svg";
import ss2 from "../../assets/illustrations/SSS (1).svg";
import small from "../../assets/illustrations/smallsquare.svg";
import tri from "../../assets/illustrations/tri.svg";
import vector from "../../assets/illustrations/Vector.svg";
import vector1 from "../../assets/illustrations/Vector (1).svg";
// import polygon from '../../assets/illustrations/Polygon 10.svg'
import ellipsis from "../../assets/illustrations/Ellipse 60.svg";
import star2 from "../../assets/illustrations/Star 2.svg";
import star3 from "../../assets/illustrations/Star 3.svg";
import poly2 from "../../assets/illustrations//Polygon 3.svg";
// import frame from '../../assets/illustrations/headerwrapperframe.svg'
import { isValidEmail } from "../../utils/constant";

const WaitingListWrapper = ({
  setEmail,
  email,
  handleSubmit,
  error,
  setError,
  loading,
}) => {
  const imageData = [one, two, three, four, five, six];

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (isValidEmail(email)) {
      setError("");
      setEmail(e.target.value);
    } else {
      setError("Email is not valid.");
    }
  };

  return (
    <div className={styles.Container}>
      {/* illustrations */}

      <div className={styles.Illustrations}>
        <img src={sss} alt="" className={styles.sss} />
        <img src={middle} alt="" className={styles.middle} />
        <img src={ss1} alt="" className={styles.ss1} />
        <img src={ss2} alt="" className={styles.ss2} />
        <img src={small} alt="" className={styles.small} />
        <img src={small} alt="" className={styles.small2} />
        <img src={tri} alt="" className={styles.tri} />
        <img src={vector} alt="" className={styles.vector} />
        <img src={vector1} alt="" className={styles.vector1} />
        {/* <img src={polygon} alt="" className={styles.polygon} /> */}
        <img src={ellipsis} alt="" className={styles.ellipsis} />
        <img src={star2} alt="" className={styles.star2} />
        <img src={star3} alt="" className={styles.star3} />
        <img src={poly2} alt="" className={styles.poly} />
      </div>

      {/* end of illustrations */}
      <div className={styles.IconContainer}>
        {imageData.map((data, index) => {
          return <img className={styles.Icon} key={index} src={data} alt="" />;
        })}
      </div>
      <div className={styles.TitleContainer}>
        <span className={styles.Span}>
          <Title title="Early Access Alert! Be Among " />
          <br />
          <Title title=" the First to Experience  " />
          <br />
          <Title color="#5E9300" title=" KANTA Exchange!" />
        </span>
        <span className={styles.Span2}>
          <Title title="Early Access Alert! Be Among the First to Experience " />
          <br />
          <Title color="#5E9300" title=" KANTA Exchange!" />
        </span>
        <br />
        <br />
        <Paragraph
          className={styles.ListText}
          fontSize={"16px"}
          fontWeight={"400"}
          text="The most secure platform to convert and spend "
        />
        <br />
        <Paragraph
          className={styles.ListText}
          fontSize={"16px"}
          fontWeight={"400"}
          text=" cryptocurrencies like local fiat "
        />
      </div>

      <div className={styles.EmailContainer}>
        <input
          placeholder="Enter email address"
          onChange={handleChange}
          value={email}
        />
        <button onClick={handleSubmit} disabled={error && true}>
          {" "}
          {loading ? "Loading..." : "Get Early Access"}
        </button>
      </div>
      {error && (
        <p style={{ color: "red", marginTop: "1rem", fontFamily: "Poppins" }}>
          {" "}
          {error}
        </p>
      )}
      {/* <p className={styles.Estimate}>Estimated settlement time 1 minutes</p> */}
    </div>
  );
};

export default WaitingListWrapper;
