import React from "react";
import Paragraph from "../../ui/Paragragh/Paragraph";
import styles from "./styles.module.css";
import pic from "../../assets/images/servicehead.svg";
import bg from "../../assets/illustrations/servicebg.svg";
import pos from "../../assets/illustrations/pos.svg";
import stor from "../../assets/illustrations/store.svg";

const ServiceHeaderWrapper = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.One}>
        <img src={bg} alt="" />
        <Paragraph
          fontSize="40px"
          fontWeight="500"
          className={styles.ServiceTitle}
          text="Simple Crypto To Fiat solutions To Power Your Business"
        />
        <br />
        <br />
        <Paragraph
          fontSize={"24px"}
          fontWeight={"400"}
          className={styles.ServiceText}
          text="Implement crypto payment into your existing workflow, integrating KANTA payment solutions with traditional finance."
        />
      </div>
      <div className={styles.Two}>
        <img src={pic} alt="" />
        <div className={styles.FlexContainer}>
          <div className={styles.Flex}>
            <img src={pos} alt="" />
            <div>
              <Paragraph
                className={styles.ServiceFlexText}
                text="+"
                fontSize={"20px"}
              />
              <Paragraph
                fontSize="16px"
                fontWeight={"400"}
                whiteSpace="nowrap"
                text="POS Merchant "
                className={styles.FlexText}
              />
            </div>
            <div></div>
          </div>
          <div className={styles.Flex}>
            <img src={stor} alt="" />
            <div>
              <Paragraph
                text="+"
                className={styles.ServiceFlexText}
                fontSize={"20px"}
              />
              <Paragraph
                fontSize="16px"
                className={styles.FlexText}
                text="Stores"
                fontWeight={"400"}
              />
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHeaderWrapper;
