import React from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import dojah from "../../assets/logo/dojah.svg";
import fireblock from "../../assets/logo/fireblock.svg";
import fluid from "../../assets/logo/fluid.svg";
import tech from "../../assets/logo/otech.svg";
import yellow from "../../assets/logo/yellowcard.svg";

const PartnerWrapper = () => {
  const data = [yellow, dojah, fluid, fireblock, tech];

  return (
    <div className={styles.PartnerContainer}>
      <Paragraph
        color="rgba(0, 0, 0, 1)"
        fontWeight="600"
        text="Our Trusted Global Partner"
        fontSize="32px"
      />
      <div className={styles.Marquee}>
        <div className={styles.ScrollingContent}>
          {data.map((logo, index) => (
            <img
              src={logo}
              key={index}
              alt={`Partner ${index}`}
              className={styles.Logo}
            />
          ))}
          {/* Duplicate the logos for seamless scrolling */}
          {data.map((logo, index) => (
            <img
              src={logo}
              key={`duplicate-${index}`}
              alt={`Partner Duplicate ${index}`}
              className={styles.Logo}
              id={styles.Duplicate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerWrapper;
