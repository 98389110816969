import React from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import bq from "../../assets/images/bq.svg";
import ma from "../../assets/images/ma.svg";
import mc from "../../assets/images/mc.svg";
import aki from "../../assets/images/aki.svg";
import afy from "../../assets/images/afy.svg";
import polygon from "../../assets/illustrations/Polygon 9.svg";

const MeetTeamWrapper = () => {
  const team = [
    {
      id: 1,
      name: "Bakre Qussim",
      position: "Senior Product Designer Co-Founder",
      img: bq,
    },
    {
      id: 2,
      name: "Musaddiq Askira",
      position: "Full Stack Developer Co-Founder",
      img: ma,
    },
  ];
  const advisors = [
    {
      id: 1,
      name: "Akibobola",
      position: "Legal Advisor",
      img: aki,
    },
    {
      id: 2,
      name: "Askira F Yerima",
      position: "Advisor",
      img: afy,
    },
    {
      id: 3,
      name: "Matteo Coppola M",
      position: "Blockchain Expert and CEO Fluid Team",
      img: mc,
    },
  ];
  return (
    <div className={styles.MeetContainer}>
      <Paragraph className={styles.MeetText} text="Meet the TEAM" />
      <img src={polygon} alt="" />
      <div className={styles.TeamContainer}>
        {team.map((data) => {
          return (
            <div key={data.id} className={styles.Team}>
              <img src={data.img} alt="" />
              <br />
              <Paragraph
                fontWeight={600}
                whiteSpace={"nowrap"}
                text={data.name}
              />
              <br />
              <Paragraph
                fontSize="16px"
                fontWeight={500}
                color="rgba(0, 0, 0, 0.8)"
                text={data.position}
              />
            </div>
          );
        })}
      </div>
      <br />
      <br />
      <br />
      <br />
      <Paragraph
        color="rgba(0, 0, 0, 0.5)"
        fontWeight={600}
        fontSize="36px"
        text="Advisors"
      />
      <div className={styles.TeamContainer}>
        {advisors.map((data) => {
          return (
            <div key={data.id} className={styles.Team}>
              <img src={data.img} alt="" />
              <br />
              <Paragraph fontWeight={600} text={data.name} />
              <br />
              <Paragraph
                fontSize="16px"
                fontWeight={500}
                color="rgba(0, 0, 0, 0.8)"
                text={data.position}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MeetTeamWrapper;
