import React from "react";
import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import { Link } from "react-router-dom";
import polygon from "../../assets/illustrations/Polygon 9.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBlogCategory, getBlogs } from "../../utils/service";
import { truncateText } from "../../utils/constant";

const BlogContentWrapper = () => {
  const [dataInfo, setDataInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await getBlogs();

      setDataInfo(result?.data || []);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        toast.error(error.message + " Please check your internet connection");
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchCatgoryData = async () => {
    setLoading(true);
    try {
      const result = await getBlogCategory();

      setCategories(result?.data || []);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        toast.error(error.message + " Please check your internet connection");
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCatgoryData();
  }, []);

  const [selectedWord, setSelectedWord] = useState("");

  // Filter data based on selected word
  const filteredData = dataInfo?.filter((item) =>
    selectedWord
      ? item?.attributes?.category?.data[0]?.attributes?.name === selectedWord
      : true
  );

  const handleWordClick = (word) => {
    setSelectedWord((prevWord) => (prevWord === word ? null : word));
  };

  return (
    <div className={styles.ContentContainer}>
      <ToastContainer position="top-center" />
      <div className={styles.ButtonContainer}>
        {
          categories?.map((data) => {
            return (
              <Link
                className={selectedWord === data?.attributes.name ? styles.active:''}
                 key={data?.id} onClick={() => handleWordClick(data?.attributes.category)} to=''>{data?.attributes.category}</Link>
            )
          })
        }
      </div>
      <div className={styles.BlogContainer}>
        <img src={polygon} alt="" />
        {filteredData?.length > 0
          ? !loading &&
            Array.isArray(filteredData) &&
            filteredData?.map((info) => {
              return (
                <div className={styles.Blog} key={info?.attributes.id}>
                  <div>
                    <img
                      src={info?.attributes.img?.data.attributes.url}
                      alt={info?.attributes.img.name}
                    />
                  </div>
                  <div>
                    <Paragraph
                      fontWeight="500"
                      fontSize="16px"
                      text={info?.attributes.blogTitle}
                    />
                    <br /> <br />
                    <Paragraph
                      fontSize="12px"
                      fontWeight={"400"}
                      color="rgba(0, 0, 0, 0.9)"
                      text={info?.attributes.summary}
                      truncate={200}
                    />
                    <br />
                    <br />
                    <Link className={styles.Learnmore} to="">
                      Learn more
                    </Link>
                  </div>
                </div>
              );
            })
          : !loading &&
            Array.isArray(dataInfo) &&
            dataInfo?.map((info) => {
              return (
                <div className={styles.Blog} key={info?.attributes.id}>
                  <div>
                    <img
                      src={info?.attributes.img?.data.attributes.url}
                      alt={info?.attributes.img.name}
                    />
                  </div>
                  <div>
                    <Paragraph
                      fontWeight="500"
                      fontSize="16px"
                      text={info?.attributes.blogTitle}
                    />
                    <br /> <br />
                    <Paragraph
                      fontSize="12px"
                      fontWeight={"400"}
                      color="rgba(0, 0, 0, 0.9)"
                      text={info?.attributes.summary}
                    />
                    <br />
                    <br />
                    <Link className={styles.Learnmore} to="">
                      Learn more
                    </Link>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default BlogContentWrapper;
