import React from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import phone from "../../assets/images/phoneimg.svg";
import star from "../../assets/icons/starIcon.svg";
import one from "../../assets/images/BTC - Bitcoin.svg";
import two from "../../assets/images/BAT - Basic Attention Token.svg";

const ExchangeWrapper = () => {
  return (
    <div className={styles.Container}>
      <Paragraph
        className={styles.ExchangeTitle}
        text="More than just a Exchange"
      />
      <div className={styles.ExchangeBlock}>
        <img className={styles.Imgone} src={one} alt="" />
        <img className={styles.Imgtwo} src={two} alt="" />
        <div className={styles.FirstBox}>
          <div
            style={{
              marginBottom: "3rem",
            }}
          >
            <Paragraph
              className={styles.CommunityTitle}
              text="Built with community "
            />
            <br /> <br />
            <Paragraph
              className={styles.CommunityText}
              text="The secure way to connect both DeFi to TraFi & vice versa. "
            />
          </div>
          <div>
            <div className={styles.Reasons}>
              <img src={star} alt="" />
              <Paragraph
                className={styles.ReasonsText}
                text="Support For Various Web3  Wallet"
              />
            </div>
            <div className={styles.Reasons}>
              <img src={star} alt="" />
              <Paragraph
                className={styles.ReasonsText}
                text="Access To Decentralized Application "
              />
            </div>
            <div className={styles.Reasons}>
              <img src={star} alt="" />
              <Paragraph
                className={styles.ReasonsText}
                text="Automated OTC Desk"
              />
            </div>
            <div className={styles.Reason}>
              <img src={star} alt="" />
              <Paragraph
                className={styles.ReasonsText}
                text="Crypto Payment Gateway "
              />
            </div>
          </div>
        </div>
        <div className={styles.SecondBox}>
          <img
            src={phone}
            alt=""
            style={{
              width: "400px",
            }}
          />
        </div>
        <div className={styles.LastBox}>
          <Paragraph
            fontWeight="600"
            whiteSpace="nowrap"
            fontSize="36px"
            text="User side encryption "
          />
          <br /><br /><br />
          <div>
            <Paragraph
              fontFamily="Inter"
              className={styles.CyptoText}
              color="rgba(105, 50, 212, 1)"
              text="Not your Kays, Not your Crypto."
            />
            <br /> <br />
            <Paragraph
              className={styles.CyptoText}
              textAlign="left"
              fontWeight="400"
              fontFamily="Inter"
              text="Our multi-layered infrastructure ensure your data remains encrypted & safe while using our services  "
            />
          </div>
          
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              marginTop:"2rem"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Paragraph
                className={styles.CyptoText}
                color="rgba(105, 50, 212, 1)"
                text="your crypto,"
              />
              <Paragraph
                className={styles.CyptoText}
                color="rgba(105, 50, 212, 1)"
                text="your choice"
              />
            </div>
            <Paragraph
              fontWeight={400}
              fontFamily="Inter"
              className={styles.CommunityText}
              text="Enjoy seamless, transparent transactions."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeWrapper;
