import React from "react";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import polygon from "../../assets/illustrations/Polygon 7.svg";
import polygon2 from "../../assets/illustrations/polygon2.svg";
import foundation1 from "../../assets/illustrations/foundation1.svg";
import foundation2 from "../../assets/illustrations/foundation2.svg";
import foundation3 from "../../assets/illustrations/foundation3.svg";
import foundation4 from "../../assets/illustrations/foundation4.svg";

const FoundationWapper = () => {
  const data = [
    {
      id: 1,
      title: "Privacy Layer",
      img: foundation1,
      detail:
        "Our server uses zero-knowledge security architecture to protect sensitive information. This approach safeguards personal data under the owner's control, demonstrating our commitment to data privacy and decentralization.",
    },
    {
      id: 2,
      img: foundation2,
      title: "Interoperability",
      detail:
        "KANTA will supports a wide range of cryptocurrencies on different blockchain, like.  $USDT, $USDC, $BTC, $ETH, $ADA.",
    },
    {
      id: 3,
      img: foundation3,
      title: "Backup",
      detail:
        "We understand the frustration of forgetting passwords, so we've created a simple and secure recovery feature.With KANTA, you can easily reset your account using face identity verification.",
    },
    {
      id: 4,
      img: foundation4,
      title: "Non-custodial",
      detail:
        "Meaning users retain control and ownership of their digital assets.",
    },
  ];
  return (
    <div className={styles.Container}>
      <img src={polygon} alt="" />
      <div className={styles.HeadingSection}>
        <Paragraph
          fontSize="32px"
          fontWeight="600"
          text="Built on a solid foundation"
        />
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paragraph
            textAlign="center"
            fontSize={"20px"}
            fontWeight={"400"}
            className={styles.FoundationText}
            text="Enjoy the convenience of spending digital assets with Kanta,"
          />
          <Paragraph
            textAlign="center"
            fontSize={"20px"}
            fontWeight={"400"}
            className={styles.FoundationText}
            text=" while prioritizing users privacy. To attract crypto users."
          />
        </div>
        <div>
          {data.map((info) => {
            return (
              <div key={info.id} className={styles.Box}>
                <img src={info.img} alt={info.title} className={styles.Image} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}
                >
                  <Paragraph
                    fontWeight="600"
                    color="#000"
                    fontSize="20px"
                    text={info.title}
                  />
                  <Paragraph
                    fontSize="13px"
                    fontWeight={"400"}
                    text={info.detail}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <img src={polygon2} alt="" />
    </div>
  );
};

export default FoundationWapper;
