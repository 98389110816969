import React from "react";
import Paragraph from "../../ui/Paragragh/Paragraph";
import styles from "./styles.module.css";

const BlogWrapper = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.One}>
        {/* <img src={bg} alt="" /> */}
        <Paragraph className={styles.BlogTitle}  fontSize="40px" fontWeight="600" text="Blog" />
        <br />
        <br />
        <Paragraph
          color="rgba(0, 0, 0, 0.5)"
          fontWeight={"400"}
          fontSize={"20px"}
          className={styles.BlogText}
          text="Read the latest content about KANTA Exchange and Blockchain"
        />
      </div>
      <div className={styles.Two}></div>
    </div>
  );
};

export default BlogWrapper;
