import { useEffect } from "react";

import HeaderWrapper from "../../wrappers/HeaderSectionWrapper/HeaderWrapper";
import styles from "./styles.module.css";
import happyUsers from "../../assets/illustrations/happyusers.png";
import PartnerWrapper from "../../wrappers/PartnerWrapper/PartnerWrapper";
import SecondWrapper from "../../wrappers/SecondWrapper/SecondWrapper";
import FoundationWapper from "../../wrappers/FoundationWrapper/FoundationWapper";
import ExchangeWrapper from "../../wrappers/ExchangeWrapper/ExchangeWrapper";
import FaqWrapper from "../../wrappers/FAQWrapper/FaqWrapper";

import withVisibility from "../../hoc/withVisibility/withVisibility";

const HappyUsers = () => {
  return (
    <div
      className={styles.HappyContainer}
    >
      <div className={styles.HappyUsers}>
        <img src={happyUsers} alt="" className={styles.image} />
        <p>Over 1k happy users </p>
      </div>
      <br />
      <p className={styles.Landscape}>
        Shaping The Landscape Of Digital Finance
      </p>
    </div>
  );
};

const HeaderWrapperWithVisibility = withVisibility(HeaderWrapper);
const PartnerWrapperWithVisibility = withVisibility(PartnerWrapper);
const SecondWrapperWithVisibility = withVisibility(SecondWrapper);
const FoundationWapperWithVisibility = withVisibility(FoundationWapper);
const ExchangeWrapperWithVisibility = withVisibility(ExchangeWrapper);
const FaqWrapperWithVisibility = withVisibility(FaqWrapper);
const HappyUsersWithVisibility = withVisibility(HappyUsers);

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Home}>
      <HeaderWrapperWithVisibility />
      <HappyUsersWithVisibility />
      <PartnerWrapperWithVisibility />
      <SecondWrapperWithVisibility />
      <FoundationWapperWithVisibility />
      <ExchangeWrapperWithVisibility />
      <FaqWrapperWithVisibility />
    </div>
  );
}

export default Home;
