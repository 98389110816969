import React from "react";
// import styles from './styles.module.css'
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Paragraph = ({
  text,
  textAlign,
  fontFamily,
  className,
  fontSize,
  fontWeight,
  color,
  whiteSpace,
  truncate,
}) => {
  const truncateText = (text, truncate) => {
    if (!truncate || text.length <= truncate) {
      return text;
    }
    return text.substring(0, truncate) + "...";
  };

  if (truncate) {
    text = truncateText(text, truncate);
  }
  return (
    <p
      style={{
        textAlign: textAlign,
        fontFamily: fontFamily || "Poppins",
        fontWeight: fontWeight || "500",
        fontSize: fontSize || "",
        color: color || "rgba(0, 0, 0, 1)",
        display: "inline-block",
        whiteSpace: whiteSpace,
      }}
      className={className}
    >
      {truncate ? (
        <Markdown remarkPlugins={[remarkGfm]} children={text} />
      ) : (
        text
      )}
    </p>
  );
};

export default Paragraph;
