import { useEffect } from "react";

import TeamHeaderWrapper from "../../wrappers/TeamHeaderWrapper/TeamHeaderWrapper";
import styles from "./styles.module.css";
import MeetTeamWrapper from "../../wrappers/TeamHeaderWrapper/MeetTeamWrapper";

import withVisibility from "../../hoc/withVisibility/withVisibility";

const TeamHeaderWrapperWithVisibility = withVisibility(TeamHeaderWrapper);
const MeetTeamWrapperWithVisibility = withVisibility(MeetTeamWrapper);

function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Team}>
      <TeamHeaderWrapperWithVisibility />
      <MeetTeamWrapperWithVisibility />
    </div>
  );
}

export default Team;
