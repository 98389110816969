import React from 'react'
import styles from './styles.module.css'
import Paragraph from '../../ui/Paragragh/Paragraph'

const TeamHeaderWrapper = () => {
  return (
    <div className={styles.Container}>
      <Paragraph
        className={styles.TeamHeading}
        text="Team Background "
        fontSize="40px"
        fontWeight="600"
      />
      <br />

      <Paragraph
        className={styles.TeamText}
        color="rgba(0, 0, 0, 0.5)"
        fontWeight={"400"}
        fontSize={"20px"}
        text="KANTA represents a collaborative innovation by a team of seasoned dedicated experts.
This collective of intellectual minds, boasting diverse backgrounds in technology, design, and development, comprises the following individuals. "
      />
    </div>
  );
}

export default TeamHeaderWrapper